import React, { useEffect, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Image, ImageBackground } from 'react-native';
import { NativeBaseProvider, Text, Box, Modal, VStack, HStack } from 'native-base';
import { Card, ListItem, Button } from 'react-native-elements';
import { Input, Icon, Stack, Center } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { API } from './APIConsumer';

import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from 'native-base';

export default function App() {
  const [prename, setPrename] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [numberOfSeats, setNumberOfSeats] = useState<number>(1);
  const [successfullySaved, setSuccessfullySaved] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => setShowModal(true), []);

  return (
    <NativeBaseProvider>
      <View style={styles.container}>

        <ImageBackground source={require('./pexels-matheus-viana-2372945.webp')} resizeMode="cover" style={styles.backgroundImage}>
          <View style={styles.card}>
            <Card>
              <Card.Title>Reservierung für Jahresfeier 2024 🎼</Card.Title>

              <Card.Divider />

              {successfullySaved === true && <Card.Title>Deine Reservierung ist eingegangen. Vielen Dank! ❤️</Card.Title>}

              {successfullySaved === false && <Stack space={4} w="100%" alignItems="center">
                <Input
                  onChangeText={value => setPrename(value)}
                  value={prename}
                  w={{ base: "75%" }}
                  InputLeftElement={
                    <Icon
                      as={<MaterialIcons name="person" />}
                      size={5}
                      ml="2"
                      color="muted.400"
                    />
                  }
                  placeholder="Vorname"
                />

                <Input
                  onChangeText={value => setSurname(value)}
                  value={surname}
                  w={{ base: "75%" }}
                  InputLeftElement={
                    <Icon
                      as={<MaterialIcons name="person" />}
                      size={5}
                      ml="2"
                      color="muted.400"
                    />
                  }
                  placeholder="Nachname"
                />

                <Input
                  onChangeText={value => setEmail(value)}
                  value={email}
                  w={{ base: "75%" }}
                  InputLeftElement={
                    <Icon
                      as={<MaterialIcons name="email" />}
                      size={5}
                      ml="2"
                      color="muted.400"
                    />
                  }
                  placeholder="E-Mail Adresse"
                />
                <Input
                  onChangeText={value => setPhone(value)}
                  value={phone}
                  w={{ base: "75%" }}
                  InputLeftElement={
                    <Icon
                      as={<MaterialIcons name="phone" />}
                      size={5}
                      ml="2"
                      color="muted.400"
                    />
                  }
                  placeholder="Telefonnummer"
                />

                {/* <Center flex={1}> */}
                <Text>{'Anzahl Plätze:'}</Text>
                <NumberInput
                  w={{ base: "100%" }}
                  InputLeftElement={
                    <Icon
                      as={<MaterialIcons name="confirmation-number" />}
                      size={5}
                      ml="2"
                      color="muted.400"
                    />
                  }
                  min={1}
                  onChange={value => setNumberOfSeats((typeof value === 'number') ? value : 0)}
                  value={numberOfSeats + ''}
                >
                  <NumberInputField isDisabled={true} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                {/* </Center> */}

                <Button
                  disabled={false}
                  title="Reservieren 🎟️"
                  onPress={() => {
                    API.get().createReservation(prename, surname, email, phone, numberOfSeats, () => {
                      setSuccessfullySaved(true);
                      setPrename("");
                      setSurname("");
                      setEmail("");
                      setPhone("");
                      setNumberOfSeats(2);
                    }, () => { });
                  }}
                />
              </Stack>}
            </Card>
          </View>

          <StatusBar style="auto" />
        </ImageBackground>
      </View>
    </NativeBaseProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover', // or 'stretch'
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  card: {
    maxWidth: '500px',
    width: '90%'

  }
});
