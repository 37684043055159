const BASE_URL = 'https://api.mvw-jahresfeier.de/';

class APIConsumerClass {
    baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  createReservation(prename: string, surname: string, email: string, phone: string, numberOfSeats: number, successFunction: Function, failFunction: Function) {
    this.postJSONToAPI("reservation", {
        prename: prename,
        surname: surname,
        email: email,
        phonenumber: phone,
        numberOfSeats: numberOfSeats
    }, successFunction, failFunction);
  }

  postJSONToAPI(api: string, bodyObject: any, successFunction: Function, failFunction: Function) {
    console.log(this.baseURL + api);
    fetch(this.baseURL + api, {
      method: 'POST',
      body: JSON.stringify(bodyObject),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    })
      .then((response) => {
        if (response.status != 200) {
          failFunction(response.status);
          return;
        }
        response.json().then(
          (json) => {
            successFunction(json);
          }
        );
      })
      .catch(() => { failFunction(500); });
  }
}

class APIConsumersClass {
  P = new APIConsumerClass(BASE_URL);
  default = this.P;

  get() {
    return this.default;
  }

  setDefaultConsumerP() {
    this.default = this.P;
  }
}

export const API = new APIConsumersClass();
